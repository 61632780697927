@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
$primary : #0085CA;
@import '~bootstrap/scss/bootstrap.scss';

.open-sans-400 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

body {
  @extend .open-sans-400;
}

.App {
  min-height: 100vh;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
}

.password-toggle {
  position: relative;

  .password-toggle-btn {
    position: absolute;
    right: 10px;
    top: 15px;
  }
}

/* Dashboard.css */
.dashboard-container {
  display: flex;
  height: 100vh;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
}

.main-content {
  flex: 1;
  padding: 100px;
}

.table-container,
.video-upload-container {
  margin-bottom: 20px;
}

.video-upload-container {
  margin-bottom: 40px;
  /* Extra space for separation */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table:not(.select-brand-table) th,
.table:not(.select-brand-table) td {
  padding: 8px;
}

.table th {
  background-color: #2c3e50;
  color: white;
}

.table-middle {
  td {
    vertical-align: middle;
  }
}

.video-upload p {
  margin: 0;
}

/* VideoUpload.css */

.upload-content {
  justify-content: space-between;
  width: 100%;

  &.textarea_section {
    flex-wrap: wrap;

    .left-content {
      width: 100%;

      textarea {
        width: 100%;

      }
    }
  }

  .left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;


  }

}


.left-content p {
  margin: 10px 0 5px 0;
}

.left-content small {
  color: #666;
}

.right-content {
  align-items: center;
}

.right-content button {
  padding: 8px 16px;
  background-color: #0085CA;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.right-content button:hover {
  background-color: #0085CA;
}

.right-content button:disabled {
  background-color: #0085CA;
}

.css-d7jkuz-MuiTable-root th {
  color: white;
  height: 3rem;
}

/* Sidebar.css */
.sidebar {
  width: 350px;
  background: #ecf0f1;
  color: black;
  /* Change text color to black */
  padding: 15px;
  position: relative;
  transition: transform 0.3s ease;
  transform: translateX(0);
}

.closed {
  transform: translateX(-100%);
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-icon {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 15px 0;
}

.sidebar ul li button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  text-align: left;
  padding: 0;
}

.modal-configuration {
  margin-top: 20px;
}

.modal-configuration h3 {
  margin-bottom: 10px;
}

.modal-configuration h4 {
  margin-bottom: 10px;
}

.modal-configuration label {
  display: block;
}

.modal-configuration input[type="range"] {
  width: 100%;
  margin-top: 5px;
}

.open-sidebar-btn {
  background: #3498db;
  border: none;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.open-sidebar-btn:hover {
  background: #2980b9;
}

/* Dashboard.css */
.dashboard-container {
  position: relative;
}

.dashboard-header {
  position: absolute;
  top: 15px;
  right: 4rem;
}

.menu-button {
  background: none;
  border: none;
  color: #000;
  font-size: 1.5em;
  cursor: pointer;
}

.logout-menu {
  position: absolute;
  top: 40px;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
}

.logout-menu button {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}

.logout-menu button:hover {
  background-color: #f2f2f2;
}

.video-details-table {
  width: 96%;
  border-collapse: collapse;
  margin: auto;
}

.video-player-modal {
  width: 96%;
  border-collapse: collapse;
  margin: auto;
}

.video-details-table th,
.video-details-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.video-details-table th {
  background-color: #f2f2f2;
}

.video_links {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}

.video_links span {
  background-color: #e1e1e1;
  padding: 5px 15px;
  margin: 4px;
  border-radius: 25px;
}

button.Toastify__close-button {
  width: auto;
  margin: 0;
}

.Toastify__toast-body {
  width: 100%;
}

/////////////////////////////


.video-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.border-dashed {
  background-image: repeating-linear-gradient(-1deg, #707070, #707070 8px, transparent 8px, transparent 11px, #707070 11px), repeating-linear-gradient(89deg, #707070, #707070 8px, transparent 8px, transparent 11px, #707070 11px), repeating-linear-gradient(179deg, #707070, #707070 8px, transparent 8px, transparent 11px, #707070 11px), repeating-linear-gradient(269deg, #707070, #707070 8px, transparent 8px, transparent 11px, #707070 11px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.upload-area {
  @extend .border-dashed;
  padding: 30px 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}

.submit-url-area {
  @extend .border-dashed;
  padding: 30px 1rem;
  display: flex;
  flex-direction: column;
  justify-content: between;
  cursor: pointer;
  gap: 1.5rem;
  height: 100%;

  .submit-url-textarea {
    border: none;
    height: 100%;
    background-color: #F7F7F7;
    border-radius: 0;
    padding: 1rem;
  }
}

.upload-actions {
  margin-top: 20px;
}

.upload-actions button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.upload-actions button:hover {
  background-color: #0056b3;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;

  li {
    margin: 0 5px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 42px;
      height: 42px;
      background-color: #e1e1e1;
      border-radius: 5px;
      text-decoration: none;
      transition: 0.5s;
      padding: 5px;
      font-weight: 600;

      &:hover {
        color: #0d6efd !important;
      }
    }
  }

  .pagination__link--active {
    a {
      background-color: #0085CA;
      color: white;

      &:hover {
        color: white !important;
      }
    }
  }

  .password-container {
    position: relative;
  }

  .password-toggle-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
    font-size: 0.9rem;
  }

  .form-check {
    display: flex;
    align-items: center;
  }
}

.alice-carousel__wrapper li {
  text-align: center;
}

.alice-carousel__wrapper li img {
  height: 700px;
  width: 700px;
  padding-top: 5%;
  padding-bottom: 5%;
  margin: 0;
}

.alice-carousel__prev-btn {
  text-align: right !important;
  position: absolute !important;
  right: 0 !important;
  width: 50px !important;
  height: 60px !important;
  font-size: 30px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: calc(50% - 35px) !important;
  left: 0 !important;
}

.alice-carousel__next-btn {
  text-align: left;
  position: absolute !important;
  right: 0 !important;
  width: 50px !important;
  height: 60px !important;
  font-size: 30px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: calc(50% - 35px) !important;
  right: 0 !important;
}

.thumbnails {
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

.table-alice-carousel {
  max-width: 700px;

  .mySwiper {
    padding: 0 30px;
    width: calc(100% - 0px);
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 0px;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 0px;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after,
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    font-size: 22px;
  }

}

.video-details-modal {
  z-index: 1050;

  .modal-content {
    border: none;
    border-radius: 0.25rem;
  }

  .modal-header {
    background-color: $primary;
    color: #fff;
    padding: 0.9rem 0.9rem 0.9rem 1.5rem;
    justify-content: space-between;
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}

.video-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  .video-detail {
    display: flex;
    align-items: center;
    gap: 1rem;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .video-detail-title {
      font-size: 18px;
      color: #000000;
    }

    .video-detail-desc {
      flex: 1 1 auto;
      font-size: 1rem;
      color: #4D4D4D;
    }
  }
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  display: inline-block;
  /* Ensures the icon rotates around its center */
  animation: rotate 0.8s linear infinite;
  /* Adjust the duration (2s) as needed */
}

.status-progressbar {
  min-width: 100px;

  >div {
    position: relative;

    span {
      // filter: drop-shadow(2px 4px 6px black);
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}

.custom-btn {
  width: auto;
  font-size: 20px;
  padding: 0.35rem 1rem;
}

.link-color {
  color: #0085CA;
}

.custom-table {
  border-radius: 0 !important;
  border: 1px solid #DEDEDE;
  box-shadow: none !important;

  th,
  td {
    padding: 14px 18px;
  }

  th {
    font-weight: 600;
    white-space: nowrap;
    background-color: #0085CA;
    color: #fff;

    &:first-of-type {
      padding-left: 2rem;
    }

    &:last-of-type {
      padding-right: 2rem;
    }
  }

  td {
    color: #000 !important;
  }
}

.dropdown-menu {
  cursor: pointer;
}

.custom-dropdown {
  .dropdown-menu {
    border: none;
    padding: 0;
  }

  .custom-dropdown-menu {
    padding: 1rem 0.5rem;
    background-color: #F4F4F4;
    border: 1px solid #DEDEDE;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;

  }
}

.btn-primary {
  color: #fff;
  border-radius: 4px;

  &:disabled,
  &:hover,
  &:focus,
  &:active,
  &.active,
  &:focus:active {
    color: #fff;
  }
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  border-color: transparent;
}

.w-md-80 {
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    width: 80%;
  }
}

.break-word {
  word-wrap: break-word;
  word-break: break-word;
}


.delete-confirm {
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  .inner {
    width: 100%;
    max-width: 435px;
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  }
}

.header {
  .nav-link {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
    font-weight: 600;
  }

  .navbar-collapse {
    @include media-breakpoint-down(lg) {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
      position: relative;
      padding: 0 12px;
    }
  }
}

.dealers-dropdown {
  @include media-breakpoint-up(md) {
    width: 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
  }

  @include media-breakpoint-down(md) {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
}

.total-videos {
  @include media-breakpoint-down(lg) {
    display: flex;
    justify-content: center;

    .card {
      width: 300px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 2rem;
    padding-bottom: 1rem;

    .card {
      height: 100%;

      .card-body {
        padding: 0.5rem 0.25rem;
      }
    }
  }

  .card-title {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
}

.filter-options {
  input {
    border-radius: 50em 0 0 50em;
    border-right: none;
    padding: 12px 12px 12px 16px;

    &:focus {
      box-shadow: none;
      border-right: none;
    }
  }

  button {
    border-radius: 0 50em 50em 0;
    padding-right: 14px;
    margin: 0 !important;
  }
}

.custom-pagination {
  button {
    border-radius: 20px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      button {
        border-radius: 50%;
      }
    }
  }
}

.delete-confirm {
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  .delete-confirm-inner {
    width: 100%;
    max-width: 500px;
    background: #f5f6fd;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);

    .delete-confirm-title {
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 34px;
    }
  }
}

.custom-pagination {
  button {
    border-radius: 20px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    gap: 10px;

    li {
      button {
        border-radius: 50%;
      }
    }
  }
}

.p-12px {
  padding: 12px;
}

label.Mui-disabled {
  color: rgba(0, 0, 0, 0.6) !important;
}

input.Mui-disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.7) !important;
}

.size-34 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-primary-light {
  background-color: #e1f1f9;
}

.tbody-td span {
  display: block;
  text-align: center;
}

.text-black-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.report-options {
  width: 180px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.brand-label {
  color: #ffffff;
  text-align: center;
  background-color: #0085CA;
  border-radius: 50px;
  padding: 4px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;

}

.dealer-label {
  color: #ffffff;
  text-align: center;
  background-color: #0085CA;
  border-radius: 50px;
  padding: 4px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.side-brands {
  border-radius: 20px;
  height: 100vh;
  overflow-y: auto;
}
.sideDealers {
  border-radius: 20px;
  height: 100vh;
  overflow-y: auto;


}
.brand-value {
  display: flex;
}
.label-brand {
  padding-right: 20px;
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-top: 6px;
}
.brand-div {
  display: flex;
  justify-content: space-around;
}
.edit-dealers {
  vertical-align: middle;
  display: flex;
  justify-content: end;
}
.user-details-tbody {
  font-size: x-large;
}
.toggle-button {
  cursor: pointer;
  background-color: rgb(229 229 229);
  color: black;
  text-align: center;
  font-size: 20px;
  border-radius: 4px;
  padding: 10px;
  &.active{
    background-color: #0085CA;
    color: white;
  }
}
.size-42{
  width: 42px;
  height: 42px;
}