/* Signin.css */

.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.signin-form {
    width: 35rem;
    margin: auto;
    color: #333;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 40px 30px;
}

.headingsContainer {
    text-align: center;
    margin-bottom: 20px;
}

.headingsContainer h3 {
    margin-bottom: 10px;
}

.mainContainer {
    padding: 0 20px;
}

.error {
    color: #ff0000;
    font-size: 0.9rem;
    margin-top: 8px;
}

.subcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.subcontainer label {
    margin-right: 10px;
}

.forgotpsd a {
    color: #0085CA;
    text-decoration: none;
    font-weight: bold;
}

.forgotpsd a:hover {
    text-decoration: underline;
}

.register {
    text-align: center;
    margin-top: 20px;
}

.register a {
    color: #0085CA;
    text-decoration: none;
    font-weight: bold;
}

.register a:hover {
    text-decoration: underline;
}

.sigin-btn {
    background-color: #0085CA;
    color: #fff;
    padding: 14px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    width: 100%;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sigin-btn:hover {
    background-color: #0056b3;
}

.mainContainer input[type=text],
.mainContainer input[type=password] {
    width: calc(100% - 36px);
    margin: 10px 0;
    padding: 12px;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s;
    border: none !important;
}

@media (max-width:1198px) {
    .logo {
        width: 284px
    }

    .auth-container {
        padding-bottom: 6rem;
    }
}

@media (min-width:1198.98px) {
    .auth-container {
        padding-bottom: 2rem;
    }
}